import CSConstantUtil from './CSConstantUtil'
import CSServiceUtil from "./CSServiceUtil";

class CSDataUtil
{
    mDataUtil            = null;
    mFinancialYearList   = null;
    mMonthList           = null;
    mMantraList         = null;
    mActiveFinancialYear = null;
    mActiveCompany       = null;
    mActiveMonth         = null;
    mFYNameObjectMap     = {};
    
    mCSServiceUtil = CSServiceUtil.cfGetInstance();

    static cfGetInstance()
    {
        if(this.mDataUtil === undefined)
        {
            this.mDataUtil = new CSDataUtil();
        }

        return this.mDataUtil;
    }

    cfGetFinancialYearList(lCallBack)
    {
        var lInstance = CSDataUtil.cfGetInstance();
        if(!lInstance.mFinancialYearList)
        {
            lInstance.mCSServiceUtil.cfGetDataFromDataProvider (CSConstantUtil.gDataProvider_FinancialYear,
                (lDataProviderId, lCollection) =>
                {
                    if( lDataProviderId === CSConstantUtil.gDataProvider_FinancialYear)
                    {
                        lInstance.mFinancialYearList = lCollection;
                        if(lCallBack)
                        {
                            lCallBack(lCollection);
                        }

                        for(let lInd=0; lInd<lCollection.length; lInd++)
                        {
                            let lObj = lCollection[lInd]
                            lInstance.mFYNameObjectMap[lObj.name_] = lObj;
                            if(lObj.is_active_ === '1')
                            {
                                lInstance.mActiveFinancialYear = lObj.name_;
                            }
                        }
                    }
                }
            );    
        }
        else
        {
            if(lCallBack)
            {
                lCallBack(lInstance.mFinancialYearList);
            }
        }

        return lInstance.mFinancialYearList;
    }

    cfGetFinancialYearObject(lFyName)
    {
        var lInstance = CSDataUtil.cfGetInstance();
        return lInstance.mFYNameObjectMap[lFyName];
    }

    cfGetMonthList(lCallBack)
    {
        var lInstance = CSDataUtil.cfGetInstance();
        if(!lInstance.mMonthList)
        {
            lInstance.mCSServiceUtil.cfGetDataFromDataProvider (CSConstantUtil.gDataProvider_Month,
                (lDataProviderId, lCollection) =>
                {
                    if( lDataProviderId === CSConstantUtil.gDataProvider_Month)
                    {
                        lInstance.mMonthList = lCollection;
                        if(lCallBack)
                        {
                            lCallBack(lCollection);
                        }
                    }
                }
            );    
        }
        else
        {
            if(lCallBack)
            {
                lCallBack(lInstance.mMonthList);
            }
        }

        return lInstance.mMonthList;
    }

    cfGetMantraList(lCallBack)
    {
        var lInstance = CSDataUtil.cfGetInstance();
        if(!lInstance.mMantraList)
        {
            lInstance.mCSServiceUtil.cfGetDataFromDataProvider ('MANTRA_LIST',
                (lDataProviderId, lCollection) =>
                {
                    if( lDataProviderId === 'MANTRA_LIST')
                    {
                        lInstance.mMantraList = lCollection;
                        if(lCallBack)
                        {
                            lCallBack(lCollection);
                        }
                    }
                }
            );    
        }
        else
        {
            if(lCallBack)
            {
                lCallBack(lInstance.mMantraList);
            }
        }

        return lInstance.mMantraList;
    }
}

export default CSDataUtil;
