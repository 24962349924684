import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import CInputText from '../controls/CInputText';
import CSTextArea from '../controls/CSTextArea';

const SERVICE_ID = "service_pe72ag9";
const TEMPLATE_ID = "template_lvz40jb";
const PUBLIC_KEY = "WButUioLi6Ru2sevN";

const CS_Form = () => {
    const [visible, setVisible] = useState(false);

    const [mName, setName] = useState('');
    const [mEmail, setMail] = useState('');
    const [mMessage, setMessage] = useState('');
    const handleOnSubmit = (e) => {
        e.preventDefault();
        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY)
            .then((result) => {
                console.log(result.text);
                alert('Message Sent Successfully')
            }, (error) => {
                console.log(error.text);
                alert('Something went wrong!')
            });
        e.target.reset()
    };
    return (
        <div className='50vw'>
            <form class="formContainer" onSubmit={handleOnSubmit}>
                <CInputText
                    mvalue={mName}
                    pOnChange={setName}
                    placeholder={' '}
                    pName={'from_name'}
                    ptype={'text'}
                    pLabel={'Name'}
                    pLabelId='from_name'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                    pRequired={true}
                    pClassName={'mb-4'}
                />

                <CInputText
                    mvalue={mEmail}
                    pOnChange={setMail}
                    placeholder={' '}
                    pName={'from_email'}
                    ptype={'email'}
                    pLabel={'Email'}
                    pLabelId='from_email'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                    pRequired={true} pClassName={'mb-4'}
                />

                <CSTextArea
                    mvalue={mMessage}
                    pOnChange={setMessage}
                    placeholder={''}
                    pName={'message'}
                    pLabel={'Message'}
                    pLabelId='Message'
                    padding='0.5rem 0.75rem'
                    marginBottom='10px'
                    height='100%' pClassName={'mb-4'}/>
                <button
                    type='submit'
                    label="Cancel"
                    style={{backgroundColor:'#2196f3', color:'white', marginTop:'2%', border: '0px solid transparent', padding: '8px', borderRadius: '4px', width:'30%', float:'right', right:'3%'}}
                    onClick={() => { if (!visible) return; setVisible(false); }}>
                    Submit
                </button>
            </form>
        </div>
    )
}

export default CS_Form;
