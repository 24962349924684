import React from "react";
import CSTheme from "../assets/CSTheme";

const sbvds = {
        Color: CSTheme.colors.PRIMARY,
        key: 'chess1',
        id: 1,
        ProductName: 'Pushkar Chess Club',
        SubTitle1: 'C ',
        SubTitle2: 'App Development',
        SubTitle3: 'V Subtitle 3',
        Image: "./image.png",
        Logo: "./chess_logo.png",
        PhoneNumber: '+91-9876543210',
        Email: 'sample@sample.com',
        weburl: 'https://www.google.com',       
        Banner1: './Banner.jpg',

        // MenuItems
        Home: 'Home',
        Menu1: 'Founders Info',
        Menu2: 'Gallery',
        Menu3: 'Blogger',
        Menu4: 'Admin',
        Menu5: 'Contact Us',
};

export const DataContext = React.createContext();

export const DataContextProvider = ({ children }) => {
        return (
                <DataContext.Provider value={sbvds}>{children}</DataContext.Provider>
        );
};
