
import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Ch_Classes() {
    const mclasses =
        [
            { "id": "1000", "location": "", "class": "Chess Sessions", "Date": "Every Tuesday", "sessionLength": "1 hour" },
        ]

    return (
        <div className="col-12 py-7 px-6 lg:px-9">
            <div className="text-900 text-3xl text-center font-bold ">Classes</div>
            <div className='csunderline'></div>
            <DataTable value={mclasses} className='w-full'>
                <Column field="class" header="Class"></Column>
                <Column field="location" header="Location"></Column>
                <Column field="Date" header="Date"></Column>
                <Column field="sessionLength" header="Session Length"></Column>
            </DataTable>
        </div>
    );
}