class CSConstantUtil {
    static gServiceId_GetBudgetForApproval = 'GET_BUDGET_FOR_APPROVAL';
    static gServiceId_SaveBudget = 'SAVE_BUDGET_SUMMARY';

    static gDataProvider_FinancialYear = 'FINANCIAL_YEAR_LIST';
    static gDataProvider_Month = 'MONTH_LIST';
    static gDataProvider_Company = 'COMPANY_LIST';

    static gColorTheme_MidnightBlue = ["#97A1D9", "#6978C9", "#4A5596", "#2C3365", "#111539"];
    static gColorTheme_Blue = ["#77C2FE", "#249CFF", "#1578CF", "#0A579E", "#003870"];
    static gColorTheme_Teal = ["#62BEB6", "#0B9A8D", "#077368", "#034D44", "#002B24"];
    static gColorTheme_Pink = ["#F88FB2", "#ED5C8B", "#D5255E", "#A31246", "#740030"];
    static gColorTheme_Random1 = ["#E65F8E", "#A86BD1", "#3AA5D1", "#3BB58F", "#3A63AD", "#82C272", "#00A88F", "#0087AC", "#FFC9ED"];
    static gColorTheme_Random2 = ["#005FAA", "#323B81", "#FFCA3E", "#FF6F50", "#D03454", "#9C2162", "#772F67", "#619ED6", "#E6696E"];
    static gColorTheme_Random3 = ["#6BA547", "#F7D027", "#E48F1B", "#B77EA3", "#E64345", "#60CEED", "#9CF168", "#F7EA4A", "#FBC543"];
}

export default CSConstantUtil;