import CSUtil from "./CSUtil";

class CSHelperUtil
{
    static cfGetInstance()
    {
        if(this.mHelperUtil === undefined)
        {
            this.mHelperUtil = new CSUtil();
        }

        return this.mHelperUtil;
    }

    static cfGetObjectCopy(lObject)
    {
        var lRetVal = {};
        if(lObject !=  null)
        {
            lRetVal = JSON.parse(JSON.stringify(lObject));
        }

        return lRetVal;
    }

    static cfSort(lCollection, lField1, lField2)
    {
        var lRetVal = null;
        if(lCollection && lField1)
        {
            if(lField2)
            {
                lRetVal = lCollection.sort(function(a, b){return (a[lField1] -b[lField1] || a[lField2] -b[lField2] )})
            }
            else
            {
                lRetVal = lCollection.sort(function(a, b){return (a[lField1] -b[lField1])})
            }
        }

        return lRetVal;
    }

    static cfSortInteger(lCollection, lField)
    {
        var lRetVal = null;
        if(lCollection && lField)
        {
            lRetVal = lCollection.sort(function(a, b)
                {
                    return (a[parseInt(a[lField], 10)] -b[parseInt(b[lField], 10)])
                }
            )
        }

        return lRetVal;
    }

    static cfGetDate()
    {
        var lToday = new Date();
        var lMonth = lToday.getMonth() + 1;
        var lMonthStr = (lMonth < 10) ? '0' + lMonth.toLocaleString() : lMonth.toLocaleString();
        var lDate = lToday.getDate();
        var lDateStr = (lDate < 10) ? '0' + lDate.toLocaleString() : lDate.toLocaleString();

        return (lToday.getFullYear() + '-' + (lMonthStr) + '-' + (lDateStr));
    }
    
    static cfGetTime()
    {
        var lToday = new Date();
        var lTime = lToday.getMinutes();
        var lTimeStr = (lTime < 10) ? '0' + lTime.toLocaleString() : lTime.toLocaleString();

        return (lToday.getHours() + ':' + (lTimeStr));
    }
}

export default CSHelperUtil;