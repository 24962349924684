import { InputText } from 'primereact/inputtext'
import React from 'react'
import { InputTextarea } from "primereact/inputtextarea";
import { useState } from 'react';
import CS_Form from './CS_Form';
function CS_Contact() {
    const [value, setValue] = useState('');
    return (
        <div >
            <div className="col-12 text-center  ">
                {/* <img src="./cs_logo_latest.png" alt="sample" height="50" className="mb-3" /> */}
                <div className="text-900 text-3xl font-bold ">Contact Us</div>
                <div className='csunderline'></div>
            </div>
            <div className="col-12 surface-card py-3 px-4 flex flex-wrap justify-content-between lg:px-8">
                <div className='col-12 lg:col-12 lg:px-8'>
                    {/* <div className="text-xl font-bold line-height-3 mb-3">GET IN TOUCH</div> */}
                    {/* <div className="text-700 font-medium line-height-3 text-justify mb-5 pr-8">We look forward to hearing from you! Whether you have questions, feedback, insterest in donating to the institution or would like to learn more about our institution, we are here to assist you. Please feel free to reach out to us through any of the means mentioned here.
                </div> */}
                    {/* <div className="block font-medium p-1 border-round">
                        <h3 className="flex align-items-center ">
                            <i className="pi pi-phone mr-3"></i><span>(925)-394-6216</span></h3>
                    </div>
                    <div className="block font-medium p-1 border-round">
                        <h3 className="flex align-items-center ">
                            <i className="pi pi-inbox mr-3"></i><span>abhishikth.ayyagari@gmail.com</span></h3>
                    </div>
                    <div className="block font-medium p-1 border-round sm:pr-0" >
                        <h3 className="flex align-items-center">
                            <i className="pi pi-directions mr-3"></i>
                            <span> 9 East Central Ave, Paoli, PA 19301</span>
                        </h3>
                    </div> */}
                    {/* <div className="text-900 text-xl font-bold">Enquiry</div> */}
                    <div className="block text-center flex flex-wrap mb-3 justify-content-center font-medium p-1 border-round">
                        <h3 className="flex align-items-center ">
                            <i className="pi pi-inbox mr-3"></i><span>ayyagariabhishikth@gmail.com</span></h3>
                    </div>
                    <CS_Form />
                </div>
               
                {/* <div className="col-12 lg:col-6" >
               <iframe width="100%" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=9%20East%20Central%20Ave,%20Paoli,%20PA%2019301+(THE%20CHECKMATE%20CHESS%20CLUB)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps vehicle tracker</a></iframe>

                </div> */}
            </div>
        </div>
    )
}

export default CS_Contact