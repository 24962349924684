import CSRequestUtil from "./CSRequestUtil";
import CSFormUtil from "./CSFormUtil";
import CSDataUtil from "./CSDataUtil";

class CSUtil
{
    mAccessPrivilegeList    = {};
    mActionPrivilegeList    = {};
    mAppLayoutList          = {};
    mAppMenuList            = {};
    mAppIcons               = {};
    mDataProviderMap        = {};
    mUtil                   = null;
    mMenuIdObjectMap        = {};
    mMenuIdChildrenMap      = {};
    mRootId                 = null;
    mServiceList            = null;
    mServiceCodeObjectMap   = {};
    mMenu                   = {};

    static mCSLogo              = require('../assets/images/logo.png');
    static mEmployee            = require('../assets/images/logo.png');
    static mLayoutMapIdIconMap  = {};
    static mNameIconMap         = {};
    static mDashboard           = {};
    static mApplicationHeight   = null;
    static mFormTitle           = 'BMS';
    static mAppName             = 'BMS';

    static cfGetInstance()
    {
        if(this.mUtil === undefined)
        {
            this.mUtil = new CSUtil();
        }

        return this.mUtil;
    }

    cfSetActionPrivilegeList(lActionPrivList)
    {
        if(lActionPrivList)
        {
            var lList = Object.values(lActionPrivList);
            for(var ind=0; ind<lList.length; ind++)
            {
                var lActionObject = lList[ind];
                if(lActionObject.app_id_)
                {
                    var app_act_privList = this.mActionPrivilegeList[lActionObject.app_id_];
                    if(!app_act_privList)
                    {
                        app_act_privList = [];
                        this.mActionPrivilegeList[lActionObject.app_id_] = app_act_privList;
                    }

                    app_act_privList.push(lActionObject);
                }
            }
        }
    }

    cfSetAccessPrivilegeList(lAccessPrivList)
    {
        this.mAccessPrivilegeList = lAccessPrivList;
    }

    cfSetAppIcons(lMenuIcons)
    {
        if(lMenuIcons !== null)
        {
            for(var ind=0; ind<lMenuIcons.length; ind++)
            {
                CSUtil.mNameIconMap[lMenuIcons[ind].cs_picture_code_] = lMenuIcons[ind].cs_picture_name_;
                if(lMenuIcons[ind].cs_layout_map_ref_id_)
                {
                    CSUtil.mLayoutMapIdIconMap[lMenuIcons[ind].cs_layout_map_ref_id_] = lMenuIcons[ind].cs_picture_name_;
                }
            }
        }
    }

    cfSetMenuItems(lMenuItems)
    {
        this.mAppLayoutList = lMenuItems;
        CSUtil.cfSort(lMenuItems, 'parent_ref_id_');
        for(var ind=0; ind<lMenuItems.length; ind++)
        {
            lMenuItems[ind].label = lMenuItems[ind].layout_item_name_;
            if(!lMenuItems[ind].parent_ref_id_)
            {
                this.mRootId = lMenuItems[ind].layout_map_ref_id_;
            }

            if(lMenuItems[ind].include_in_menu_ === '1')
            {
                this.mMenuIdObjectMap[lMenuItems[ind].layout_map_ref_id_] = lMenuItems[ind];

                var lChildren = null;
                var lParentObject = this.mMenuIdObjectMap[lMenuItems[ind].parent_ref_id_];
                if(lParentObject)
                {
                    if(!lParentObject.children)
                    {
                        lParentObject.open = false;
                        lChildren = this.mMenuIdChildrenMap[lMenuItems[ind].parent_ref_id_];
                        if(lChildren)
                        {
                            lParentObject.children = lChildren;
                        }
                        else
                        {
                            lParentObject.children = [];    
                            lChildren = lParentObject.children;
                        }
                    }
                    else
                    {
                        lChildren = lParentObject.children;
                    }
                }
                else
                {
                    lChildren = this.mMenuIdChildrenMap[lMenuItems[ind].parent_ref_id_];
                    if(!lChildren)
                    {
                        lChildren = [];
                        this.mMenuIdChildrenMap[lMenuItems[ind].parent_ref_id_] = lChildren;
                    }
                }

                if(lChildren)
                {
                    lChildren.push(lMenuItems[ind]);
                }
            }
        }
    }

    cfGetMenuItem(lId)
    {
        var lRetVal = null;
        if((lId) && (this.mMenuIdObjectMap))
        {
            lRetVal = this.mMenuIdObjectMap[lId];
        }

        return lRetVal;
    }

    cfGetMenuItems()
    {
        var lMenuItems = null;
        if((this.mRootId) && (this.mMenuIdChildrenMap))
        {
            lMenuItems = this.mMenuIdChildrenMap[this.mRootId];
        }

        this.cfSetLevel(lMenuItems, 1);

        return lMenuItems;
    }

    cfSetLevel(lCollection, lInd)
    {
        if(lCollection)
        {
            for(var ind=0; ind<lCollection.length; ind++)
            {
                lCollection[ind].level_ = lInd;
                if(lCollection[ind].children)
                {
                    this.cfSetLevel(lCollection[ind].children, lInd + 1)
                }
            }
        }
    }
    
    cfGetLayoutType(lId)
    {
        var lLayoutType = null;

        if(lId !== "")
        {
            var lItem = this.mMenuIdObjectMap[lId];
            if(lItem)
            {
                lLayoutType = lItem.layout_type_;
            }
        }

        return lLayoutType;
    }
    
    cfGetLayoutCode(lId)
    {
        var lLayoutCode = null;

        if(lId !== "")
        {
            var lItem = this.mMenuIdObjectMap[lId];
            if(lItem)
            {
                lLayoutCode = lItem.layout_code_;
            }
        }

        return lLayoutCode;
    }
    
    cfInitializeApplication()
    {
        /**
         * Get Logged In Employee Details and initialization details
         */
        var lRequestUtil = new CSRequestUtil('GET', 'REQ_INIT_EMPLOYEE', 'initialize', 'REQ_INIT_EMPLOYEE');
        var lCriteriaObj = {};
        lCriteriaObj.user_id_ = CSRequestUtil.mUserId;
        lRequestUtil.cfSetRequestCriteria(lCriteriaObj);
        lRequestUtil.cfProcessRequest(this.cfInitializeApplicationHandler)
    }

    cfInitializeApplicationHandler(lResponse)
    {
        if(lResponse)
        {
            if(lResponse.hasOwnProperty('result') && lResponse.result)
            {
                let lCode;
                let lResult = lResponse.result;
                if(lResult instanceof Array)
                {
                    lCode = lResult[0].code;
                }
                else
                {
                    lCode = lResult.code;
                }

                if(lCode && (lCode === '1'))
                {
                    let lEmployeeData = null;
                    if(lResponse.hasOwnProperty('collection_') && lResponse.collection_ && (lResponse.collection_.length > 0))
                    {
                        lEmployeeData               = lResponse.collection_[0];
                        CSFormUtil.mEmployeeId      = lEmployeeData.employee_id_;
                        CSFormUtil.mDeptCode        = lEmployeeData.dept_code_;
                        CSFormUtil.mDeptName        = lEmployeeData.dept_name_;
                        CSFormUtil.mDesignation     = lEmployeeData.designation_;
                        CSFormUtil.mEmployeeName    = lEmployeeData.employee_name_;
                        CSFormUtil.mManagerId       = lEmployeeData.manager_id_;
                        CSFormUtil.mManagerName     = lEmployeeData.manager_name_;
                        CSFormUtil.mOfficeCode      = lEmployeeData.office_code_;
                        CSFormUtil.mOfficeName      = lEmployeeData.office_name_;
                        CSFormUtil.mEmployee        = lEmployeeData; 
                    }

                    if(lResponse.hasOwnProperty('financial_year_list_') || lResponse.hasOwnProperty('company_list_') || lResponse.hasOwnProperty('month_list_'))
                    {
                        let lDataUtil = CSDataUtil.cfGetInstance();
                        if(lResponse.hasOwnProperty('financial_year_list_'))
                        {
                            lDataUtil.mFinancialYearList = lResponse.financial_year_list_;
                            for(let lInd=0; lInd<lDataUtil.mFinancialYearList.length; lInd++)
                            {
                                let lObj = lDataUtil.mFinancialYearList[lInd];
                                if(lObj.is_active_ === '1')
                                {
                                    lDataUtil.mActiveFinancialYear = lObj.name_;
                                    break;
                                }
                            }
                        }

                        if(lResponse.hasOwnProperty('company_list_'))
                        {
                            lDataUtil.mMantraList = lResponse.company_list_;
                            if(lEmployeeData)
                            {
                                lDataUtil.mActiveCompany = lEmployeeData.company_code_;
                            }
                        }

                        if(lResponse.hasOwnProperty('month_list_'))
                        {
                            lDataUtil.mMonthList = lResponse.month_list_;
                            lDataUtil.mActiveMonth = CSFormUtil.gLongMonthList[(new Date().getMonth())];
                        }
                    }
                }
            }

            let lUtil = CSUtil.cfGetInstance();
            let lDataProviderNames = Object.keys(lResponse);
            let lDataProviderValues = Object.values(lResponse);
            for(let ind=0; ind<lDataProviderNames.length; ind++)
            {
                if(lDataProviderNames[ind] !== "__request__")
                {
                    lUtil.mDataProviderMap[lDataProviderNames[ind]] = lDataProviderValues[ind];
                }
            };
        }
    }
}

export default CSUtil;