import React from 'react'

export default function CS_About() {
    return (
        <div>
            <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
                <div className="text-3xl font-normal text-center text-900">CLUB CO-PRESIDENTS</div>
                <div className='csunderline mb-6'></div>
                <div className="flex flex-wrap align-items-center mb-4">
                    <div className="col-12 md:col-6">
                        {/* <img src="./pushkar_photo.png" alt="Image" className="w-full" /> */}
                        <div className="text-3xl text-center font-bold text-900 mb-1">Abhishikth Pushkar Ayyagari</div>
                    </div>
                    <div className="col-12 md:col-6 md:pl-6">
                        <div className="text-3xl text-center font-bold text-900 mb-1">Mohnish Elangovan</div>
                        {/* <div className="text-xl font-medium text-900 mb-3">President</div> */}
                    </div>
                </div>
                <div className="text-sm px-8 font-normal text-center text-900 mb-4">Abhishikth and Mohnish are experienced educators and chess professionals. They both are members of the United States Chess Federation (USCF). Both of them participated in many USCF chess tournaments in USA ranging from state to national level and placed well in those tournaments. Both of them are very interested in tutoring others and helping others build their skills.</div>
                <div className="text-sm px-8 font-normal text-center text-900">In addition to the club meeting every week on Tuesday, Abhishikth and Mohnish also offer private lessons for people. For these lessons, you have to schedule a certain time and day. The duration of the lesson can be as much as the participant wants it to be.</div>
            </div>
        </div>
    )
}
