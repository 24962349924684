import Axios from "axios"
<script src="http://localhost:8097"></script>

class CSRequestUtil
{
    static mAppCode             = 'SBVDS';
    static mProductCode         = 'SBVDS APP';
    static mProductCaption      = 'SBVDS Institutions';
    static mProductTitle        = 'SBVDS Institutions';
    static mLoginFormTitle      = 'Chinmmai Systemss';
    static mLoginFormLogo       = require('../assets/images/logo.png');
    static mProductLogo         = require('../assets/images/logo.png');
    static mCompanyLogo         = require('../assets/images/logo.png');
 
    static mUserId              = 'cs_admin';
    static mSessionId           = '1';
    static mDomainId            = 'CS_DAO';
    static mUserType            = 'GUEST';

    static mAuthenticateUrl     = 'https://studio.chinmmaisystems.com/control/react_authenticate.php';
    static mServiceUrl          = 'https://studio.chinmmaisystems.com/control/react_process_request.php';
    static mPropertiesUrl       = 'https://studio.chinmmaisystems.com/controlprocess_properties.php';

    mRequestType = ''; 
    mCriteria = {};
    mRequestKeys = null;
    mData = {};
    mServiceCode = '';
    mRequestId = '';
    mDataType = '';

    constructor(request_type = '', service_code = '', data_type = '', request_id = '')
    {
        this.mServiceCode   = service_code;
        this.mDataType      = data_type;
        this.mRequestType   = request_type;
        this.mRequestId     = request_id;
    }

    cfSetAppCode(appCode)
    {
        this.mAppCode = appCode;
    }

    cfGetAppCode()
    {
        return this.mAppCode;
    }

    cfSetUserId(user_id)
    {
        this.mUserId = user_id;
    }

    cfGetUserId()
    {
        return this.mUserId;
    }

    cfSetSessionId(session_id)
    {
        this.mSessionId = session_id;
    }

    cfGetSessionId()
    {
        return this.mSessionId;
    }

    cfGetLoginObj()
    {
        var lgn = {};
        lgn.user_id = CSRequestUtil.mUserId;
        lgn.session_id = CSRequestUtil.mSessionId;

        return lgn;
    }

    cfSetRequestCriteria(crit_obj)
    {
        if(crit_obj)
        {
            var lCriteriaNames = Object.keys(crit_obj);
            var lCriteriaValues = Object.values(crit_obj);
            for(var lInd_0=0; lInd_0<lCriteriaNames.length; lInd_0++)
            {
                if(lCriteriaValues[lInd_0])
                {
                    this.mCriteria[lCriteriaNames[lInd_0]] = lCriteriaValues[lInd_0];
                }
            }
        }
    }

    cfSetRequestKeys(lReqKeys)
    {
        this.mRequestKeys = lReqKeys;
    }

    cfSetRequestData(data_obj)
    {
        this.mData = data_obj;
    }

    cfAuthenticate(user_id, passwd, login_attempt, call_back_fn)
    {
        var request = {};
        request.Authentication = {user_id : user_id, password : passwd, login_attempt: login_attempt};
        request.criteria = {user_id : user_id, app_code_ : CSRequestUtil.mAppCode};
        request.login = {user_id : user_id, password : passwd, login_attempt: login_attempt};
        request.app_id = CSRequestUtil.mAppCode;
        if(this.mRequestKeys != null)
        {
            request.request_keys = this.mRequestKeys;
        }

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mAuthenticateUrl,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                data: JSON.stringify(request)
            }
        ).then(result => 
            {
                if(result.hasOwnProperty('data'))
                {
                    if(result.data.hasOwnProperty('status'))
                    {
                        var lStatus = null;
                        if(result.data.status instanceof Array)
                        {
                            lStatus = result.data.status[0];
                        }
                        else
                        {
                            lStatus = result.data.status;
                        }

                        var lStatusObj = {};

                        if(lStatus.code instanceof Array)
                        {
                            lStatusObj.code = lStatus.code[0];
                        }
                        else
                        {
                            lStatusObj.code = lStatus.code;
                        }

                        if(lStatus.message instanceof Array)
                        {
                            lStatusObj.message = lStatus.message[0];
                        }
                        else
                        {
                            lStatusObj.message = lStatus.message;
                        }

                        var lData = this.cfGetDataObj(result.data);
                        call_back_fn(lStatusObj, lData);
                    }
                }
            }
        ).catch((error) =>
            {
                console.log(error);
            }
        );
    }

    cfProcessRequest(call_back_fn)
    {
        if(this.mRequestType === 'GET')
        {
            this.cfProcessGetRequest(call_back_fn);
        }
        else if(this.mRequestType === 'SAVE')
        {
            this.cfProcessSaveRequest(call_back_fn);
        }
    }

    cfProcessSaveRequest(call_back_fn)
    {
        let data_obj = 
        {
            login           : this.cfGetLoginObj(),
            app_id          : CSRequestUtil.mAppCode,   
            data_type_      : this.mDataType,
            domain_id_      : CSRequestUtil.mDomainId,
            service_type_   : this.mRequestType,
            criteria        : this.mCriteria,
            data            : this.mData
        };

        if(this.mRequestId)
        {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else
        {
            data_obj.service_id_ = this.mServiceCode;
        }

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mServiceUrl,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                data: JSON.stringify(data_obj)
            }
        ).then(result => 
            {
                if(result.hasOwnProperty('data'))
                {
                    if(result.data.hasOwnProperty('status'))
                    {
                        var status = result.data.status;
                        if(status.hasOwnProperty('code'))
                        {
                            if(typeof(call_back_fn) === 'function')
                            {
                                call_back_fn(status);
                            }
                        }
                    }
                    else if(result.data.hasOwnProperty('result'))
                    {
                        var lStatus = result.data.result;
                        if(lStatus.hasOwnProperty('code'))
                        {
                            if(typeof(call_back_fn) === 'function')
                            {
                                call_back_fn(lStatus);
                            }
                        }
                    }
                }
            }
        ).catch((error) =>
            {
                console.log(error);
            }
        );
    }

    cfProcessGetRequest(call_back_fn)
    {
        let data_obj = 
        {
            login           : this.cfGetLoginObj(),
            app_id          : CSRequestUtil.mAppCode,   
            data_type_      : this.mDataType,
            domain_id_      : CSRequestUtil.mDomainId,
            service_type_   : this.mRequestType,
            criteria        : this.mCriteria
        };

        if(this.mRequestId)
        {
            data_obj.service_id_ = this.mRequestId;
            data_obj.request_id_ = this.mRequestId;
        }
        else
        {
            data_obj.service_id_ = this.mServiceCode;
        }

        if(this.mRequestKeys)
        {
            data_obj.request_keys = this.mRequestKeys;
        }

        if(!data_obj.criteria)
        {
            data_obj.criteria = {};
        }

        data_obj.criteria.logged_in_user_id_ = CSRequestUtil.mUserId;

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mServiceUrl,
                headers: 
                { 
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json' 
                },
                data: JSON.stringify(data_obj)
            }
        ).then(result => 
        {
            if(result.hasOwnProperty('data'))
            {
                if(result.data)
                {
                    var lData = this.cfGetDataObj(result.data);
                    call_back_fn(lData, this.mCriteria);
                }
            }
        }
        ).catch((error) =>
            {
                console.log(error);
                call_back_fn();
            }
        );
    }

    cfGetPropertiesRequest(call_back_fn)
    {
        let data_obj = 
        {
            app_id : CSRequestUtil.mAppCode,   
        };

        Axios(
            {
                method: 'post',
                url: CSRequestUtil.mPropertiesUrl,
                headers: 
                { 
                    'Accept': 'application/json', 
                    'Content-Type': 'application/json' 
                },
                data: JSON.stringify(data_obj)
            }
        ).then(result => 
        {
            var lProperties = result.data;
            if(lProperties.hasOwnProperty("product_name"))
            {
                CSRequestUtil.mProductCode = lProperties.product_name;
            }

            if(lProperties.hasOwnProperty("product_title"))
            {
                CSRequestUtil.mProductTitle = lProperties.product_title;
            }

            if(lProperties.hasOwnProperty("product_caption"))
            {
                CSRequestUtil.mProductCaption = lProperties.product_caption;
            }

            if(lProperties.hasOwnProperty("login_form_title"))
            {
                CSRequestUtil.mLoginFormTitle = lProperties.login_form_title;
            }

            if(lProperties.hasOwnProperty("login_form_logo"))
            {
                CSRequestUtil.mLoginFormLogo = lProperties.login_form_logo;
            }

            if(lProperties.hasOwnProperty("company_logo"))
            {
                CSRequestUtil.mCompanyLogo = lProperties.company_logo;
            }

            if(lProperties.hasOwnProperty("product_logo"))
            {
                CSRequestUtil.mProductLogo = lProperties.product_logo;
            }

            call_back_fn();
        });
    }

    cfGetDataObj(lData, lVariable)
    {
        var lRetVal = {}
        if(!lVariable)
        {
            lVariable = 'collection_';
        }

        var lDataObj = [];
        if(lData)
        {
            var lKeys = Object.keys(lData);
            if(lKeys && (lKeys.length > 0) && (lKeys.length < 1000))
            {
                var lValues = Object.values(lData);
                for(var lInd=0; lInd<lKeys.length; lInd++)
                {
                    var lKey = lKeys[lInd];
                    var lDataVal = lValues[lInd];
                    if(lDataVal && (lDataVal instanceof Array))
                    {
                        for(var lValInd=0; lValInd<lDataVal.length; lValInd++)
                        {
                            var lValObj = lDataObj[lValInd];
                            if(!lValObj)
                            {
                                lValObj = {}
                                lDataObj.push(lValObj);
                            }

                            lValObj[lKey] = lDataVal[lValInd];
                        }
                    }
                    else
                    {
                        var lChildData = this.cfGetDataObj(lDataVal);
                        if(lChildData && lChildData.hasOwnProperty('collection_'))
                        {
                            lRetVal[lKey] = lChildData.collection_;                            
                        }
                    }
                }
            }
        }

        lRetVal[lVariable] = lDataObj;

        return lRetVal;
    }
}

export default CSRequestUtil;