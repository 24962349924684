import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import React, { useContext, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { useRef } from "react";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Toast } from 'primereact/toast';
import { Password } from 'primereact/password';
import { Avatar } from 'primereact/avatar';

import { DataContext } from '../context/DataContext';
import { Hamburger_Icon } from '../controls/Hamburger_Icon';
import CInputText from '../controls/CInputText';
import CSDropdown from '../controls/CSDropdown';
import CSRequestUtil from '../util/CSRequestUtil';
import CSServiceUtil from '../util/CSServiceUtil';
import CSTextArea from '../controls/CSTextArea';
import CS_Form from './CS_Form';

export default function C_Navbar() {
    const ProductData = useContext(DataContext);
    const { ProductName, Home, Menu1, Menu2, Menu3, Menu4, Menu5, Menu6, Logo, Color, weburl } = ProductData
    const [showNavbar, setShowNavbar] = useState(false);
    const [visible, setVisible] = useState(false);
    const [loginVisible, setLogin_Visible] = useState(false);
    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar);
    };
    const closeMenuOnMobile = () => {
        if (window.innerWidth <= 1150) {
            setShowNavbar(false);
        }
      };


    const mRegister_form =
        <Dialog
            header="Enquiry"
            visible={visible}
            modal={true}
            className='w-8 md:w-max'
            appendTo={document.body}
            onHide={() => setVisible(false)}
        >
            <CS_Form/>

        </Dialog>

    return (
        <nav className="navbar">
            <div className="scontainer">
                <div className="logo">
                    <a href="/home" className="p-2 flex align-items-center">
                        <img src={Logo} alt="Logo" height="50" className="mr-0 lg:mr-2" />
                        {/* <span className='font-bold uppercase text-2xl' style={{ color: Color }}>{ProductName}</span> */}
                    </a>
                </div>
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <Hamburger_Icon />
                </div>
                <div className={`nav-elements  ${showNavbar && "active"}`}>
                    <ul className='px-2'>
                        <li>
                            <NavLink to="/" onClick={closeMenuOnMobile}>Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about" onClick={closeMenuOnMobile}>About Us</NavLink>
                        </li>
                        <li>
                            <NavLink to="/classes" onClick={closeMenuOnMobile}>Classes</NavLink>
                        </li>
                        {/* <li>
                            <NavLink to="/mobileApp" onClick={closeMenuOnMobile}>Q Contact</NavLink>
                        </li> */}

                        <li>
                            <NavLink to="/contact" onClick={closeMenuOnMobile}>Contact Us</NavLink>
                        </li>
                        <li className='flex flex-wrap justify-content-center gap-2' >
                            <Button label="Enquiry" 
                            // icon="pi pi-user"
                                className='w-6rem h-3rem bg-primary text-white border-round-lg border-3 justify-content-center text-center  lg:block'
                                style={{ padding: '12px 14px 14px 14px' }} onClick={() => setVisible(true)} onHide={() => setVisible(false)}></Button>
                            {mRegister_form}
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

