import React, { Component } from "react"

import CSHelperUtil from "../util/CSHelperUtil";
import CSServiceUtil from "../util/CSServiceUtil";
import CSRequestUtil from "../util/CSRequestUtil";
import Swal from "sweetalert2";

class CSFormUtil extends Component
{
    mFormUtil = null;

    static mUserId = null;
    static mEmployee = null;
    static mEmployeeId = null;
    static mManagerId = null;
    static mDeptCode = null;
    static mDesignation = null;
    static mEmployeeName = null;
    static mManagerName = null;		
    static mOfficeName = null;
    static mOfficeCode = null;
    static mDeptName = null;
    static mCountry = 'India';
    static mState = 'Telangana';
    static mCity = 'Hyderabad';
    static mCompanyCode = null;
    static mBranchCode = null;
    static mAYCode = null;
    static mDateFormat = 'DD-MM-YYYY';
    static mDateSaveFormat = 'YYYY-MM-DD';
    static mRupee = '&#8377;';

    static gYearList = null;
    static gMonthList = ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'];
    static gFYMonthList = ['APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC','JAN','FEB','MAR'];
    static gLongMonthList = 
    [   
        'January',      'February', 'March',    'April',
        'May',          'June',     'July',     'August', 
        'September',    'October',  'November', 'December'
    ];

    static gMonthMap = 
    {
        'JAN': 'January', 	'FEB': 'February',  'MAR': 'March', 'APR': 'April',
        'MAY': 'May', 		'JUN': 'June',      'JUL': 'July',	'AUG': 'August',
        'SEP': 'September',	'OCT': 'October',	'NOV': 'November',	'DEC': 'December'
    };

    static gWeekList = ['SUN','MON','TUE','WED','THU','FRI','SAT'];
    static gLongWeekList = ['Sunday','Moday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    static gActionIdObjMap = {};

    static gFinancialYearList = null;
    static gMonthList = null;
    static gCompanyList = null;
    static gDepartmentList = null;
    
    static cfGetInstance()
    {
        if(this.mFormUtil === undefined)
        {
            this.mFormUtil = new CSFormUtil();
        }

        return this.mFormUtil;
    }
		
    static cfInitializeYearList()
    {
        if(!CSFormUtil.gYearList)
        {
            CSFormUtil.gYearList = [];
            for(var lIndex=0; lIndex<100; lIndex++)
            {
                CSFormUtil.gYearList.addItem(2000 + lIndex);
            }
        }
    }

    cfGetCurrentTime()
    {
        var lCurrentTime;
        var lNewDate = new Date();
        let lHours = lNewDate.getHours();
        let lMinutes = lNewDate.getMinutes();
        let lSeconds = lNewDate.getSeconds();

        lCurrentTime = (`${lHours}:${lMinutes}:${lSeconds}`);

        return lCurrentTime;
    }

    static cfGetDateToString(lDate, lType = 'save', lSeparator = '-')
    {
        var lRetVal;
        if(lDate)
        {
            var lDay = lDate.getDate();
            var lMonth = lDate.getMonth() + 1;
            var lYear = lDate.getFullYear();
        
            if(lType === 'save')
            {
                lRetVal = `${lYear}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lDay<10?`0${lDay}`:`${lDay}`}`;
            }
            else
            {
                lRetVal = `${lDay<10?`0${lDay}`:`${lDay}`}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lYear}`;
            }
        }

        return lRetVal;
    }

    static cfGetStringToDate(lDate)
    {
        var lRetVal;
        if(lDate)
        {
            lRetVal = new Date(lDate);
        }

        return lRetVal;
    }

    cfGetCurrentDate(lType = 'save', lSeparator = '-')
    {
        var lCurrentDate;
        var lNewDate = new Date();
        var lDay = lNewDate.getDate();
        var lMonth = lNewDate.getMonth() + 1;
        var lYear = lNewDate.getFullYear();
        
        if(lType === 'save')
        {
            lCurrentDate = `${lYear}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lDay}`;
        }
        else if(lType === 'display')
        {
            lCurrentDate = `${lDay}${lSeparator}${lMonth<10?`0${lMonth}`:`${lMonth}`}${lSeparator}${lYear}`;
        }

        return lCurrentDate;
    }

    static cfGetConstantValue(lParamName)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();
        var lParamValue = null;
        if(lParamName)
        {
			if(lParamName === '#APP_CODE#')
			{
				lParamValue = CSRequestUtil.mAppCode;
			}
			else if(lParamName === '#EMPLOYEE_ID#')
			{
				lParamValue = CSFormUtil.mEmployeeId;
			}
			else if(lParamName === '#MANAGER_ID#')
			{
				lParamValue = CSFormUtil.mManagerId;
			}
			else if(lParamName === '#EMPLOYEE_NAME#')
			{
				lParamValue = CSFormUtil.mEmployeeName;
			}
			else if(lParamName === '#MANAGER_NAME#')
			{
				lParamValue = CSFormUtil.mManagerName;
			}
			else if(lParamName === '#USER_ID#')
			{
				lParamValue = CSFormUtil.mUserId;
			}
			else if(lParamName === '#TODAY#')
			{
				lParamValue = lFormUtil.cfGetCurrentDate();
			}
			else if(lParamName === '#YEAR#')
			{
				var lCurrentDate = new Date();
				lParamValue = lCurrentDate.getFullYear();
			}
			else if(lParamName === '#MONTH#')
			{
				lParamValue = (new Date()).getMonth() + 1;
			}
			else if(lParamName === '#TIME_NOW#')
			{
				lParamValue = lFormUtil.cfGetCurrentTime();
			}												
			else if(lParamName === '#EMPLOYEE_STATE_NAME#')
			{
				lParamValue = CSFormUtil.mState;
			}
			else if(lParamName === '#OFFICE_CODE#')
			{
				lParamValue = CSFormUtil.mOfficeCode;
			}
			else if(lParamName === '#DEPT_CODE#')
			{
				lParamValue = CSFormUtil.mDeptCode;
			}
			else if(lParamName === '#OFFICE_NAME#')
			{
				lParamValue = CSFormUtil.mOfficeName;
			}
			else if(lParamName === '#DEPT_NAME#')
			{
				lParamValue = CSFormUtil.mDeptName;
			}
			else if(lParamName === '#AY_CODE#')
			{
				lParamValue = CSFormUtil.mAYCode;
			}
			else if(lParamName === '#BRANCH_CODE#')
			{
				lParamValue = CSFormUtil.mBranchCode;
			}
			else
			{
				lParamValue = lParamName;
			}
       }

       return lParamValue;
    }

    cfGetEmployeeId()
    {
        return this.mEmployeeId;
    }
    
    cfGetManagerId()
    {
        return this.mManagerId;
    }
    
    cfGetDeptCode()
    {
        return this.mDeptCode;
    }
    
    cfGetEmployeeName()
    {
        return this.mEmployeeName;
    }
    
    cfGetManagerName()
    {
        return this.mManagerName;
    }		
    
    cfGetOfficeName()
    {
        return this.mOfficeName;
    }
    
    cfGetOfficeCode()
    {
        return this.mOfficeCode;
    }
    
    cfGetDeptName()
    {
        return this.mDeptName;
    }
    
    cfGetCountry()
    {
        return this.mCountry;
    }
    
    cfGetState()
    {
        return this.mState;
    }
    
    cfGetCity()
    {
        return this.mCity;
    }
    
    cfGetCompanyCode()
    {
        return this.mCompanyCode;
    }
    
    cfGetDateFormat()
    {
        return this.mDateFormat;
    }
    
    cfGetDateSaveFormat()
    {
        return this.mDateSaveFormat;
    }
    
    cfGetRupeeSymbol()
    {
        return this.mRupee;
    }

    cfGetControl(lFormItem, fOnChange, lFormItemMap, lClassName)
    {
        var lFormGrid = null;
        var lMinHeight;
        if(lFormItemMap)
        {
            if(lFormItem.control_type_ === 'cs_grid')
            {
                lFormGrid = lFormItemMap[lFormItem.id_]; 
                if(lFormGrid)
                {
                    for(var lGInd=0; lGInd<lFormGrid.length; lGInd++)
                    {
                        var lFormGridItem = lFormGrid[lGInd];
                        lFormGridItem.column_name_ = lFormGridItem.display_label_;
                    }
                }

                lMinHeight = '40px'
            }
        }
       
        return null;
/*        
        return(
            <CSControl 
                key                 = {lFormItem.id_}
                pMaxWidth           = {(lFormItem.max_width_) ? lFormItem.max_width_ : '300px'}
                pFormGrid           = {lFormGrid}
                pMinHeight          = {lMinHeight}
                process_change_     = {fOnChange}
                pFormLabelClassName = {lClassName}
                pLabelWidth         = {(lFormItem.label_width_) ? lFormItem.label_width_ : '90px'}
                {...lFormItem}/>
        )
*/                
}

    cfGetDetailsSections(lFormItemList, lMinSectionItems, fOnChange, lClassName)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();
        var lSections = [];
        var lNumSections = 1;
        var lNumItemsPerSection = 0;
        if(lFormItemList)
        {
            if(lFormItemList.length > (lMinSectionItems) * 2)
            {
                lNumSections = 3;
            }
            else if(lFormItemList.length > lMinSectionItems)
            {
                lNumSections = 2;
            }

            lNumItemsPerSection = Math.ceil(lFormItemList.length/lNumSections);

            var lSectionNumControlMap = {};
            var lMaxSectionItems = 0;
            CSHelperUtil.cfSort(lFormItemList, 'sequence_')
            for(var ind=0; ind<lFormItemList.length; ind++)
            {
                var lFormItem = lFormItemList[ind];
                var lSequence = parseInt(lFormItem.sequence_, 10);
                var lIndex = Math.ceil(lSequence/lNumItemsPerSection) - 1;
                var lSectionControlList = null;
                lSectionControlList = lSectionNumControlMap[lIndex];
                if(lSectionControlList == null)
                {
                    lSectionControlList = [];
                    lSectionNumControlMap[lIndex] = lSectionControlList;
                }
                
                var lCtl = lFormUtil.cfGetControl(lFormItem, fOnChange, null, lClassName);
                if(lCtl)
                {
                    lSectionControlList.push(lCtl);
                }

                lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList.length);
            }

            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FAFAFA";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }
/*
                lSection = <CSCOVerticalSection 
                    pBackgroundColor    = {lBkgColor} 
                    pId                 = {lSectionNum} 
                    key                 = {lSectionNum}
                    pNumSections        = {lNumSections}
                    pMaxSectionItems    = {lMaxSectionItems + 1}
                    pIsCriteriaSection  = {false}
                    pChildren           = {lSectionNumControlMap[lSectionNum]}/>
*/
                lSections.push(lSection);
            }
        }

        return lSections;
    }
    
    cfGetSections(lFormItemList, lMinSectionItems, lMaxSections, fOnChange, lSectionWidth, lPadding)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();   
        var lSections = [];
        var lNumSections = 0;
        if(lFormItemList)
        {
            var lFormItemList_0 = [];
            var lFormItemMap = {};
            var lParentFormItemObjectMap = {};
            var lNumCollectionSections = 0;
            CSHelperUtil.cfSort(lFormItemList, 'parent_id_', 'sequence_');
            for(var ind_0=0; ind_0<lFormItemList.length; ind_0++)
            {
                var lFormItem_0 = lFormItemList[ind_0];
                if((lFormItem_0.control_type_ === 'heading') || (lFormItem_0.control_type_ === 'cs_grid')  || (lFormItem_0.control_type_ === 'accordion') )
                {
                    lParentFormItemObjectMap[lFormItem_0.id_] = lFormItem_0;
                    lNumSections++;
                    lNumCollectionSections++;
                    if(!lFormItemMap[lFormItem_0.id_])
                    {
                        lFormItemMap[lFormItem_0.id_] = [];
                    }
                }
                else
                {
                    if(lFormItem_0.parent_id_)
                    {
                        var lFormItemList_1 = lFormItemMap[lFormItem_0.parent_id_];
                        if(!lFormItemList_1)
                        {
                            lFormItemList_1 = [];
                            lFormItemMap[lFormItem_0.parent_id_] = lFormItemList_1;
                        }

                        lFormItemList_1.push(lFormItem_0);
                    }
                    else
                    {
                        if(lFormItem_0.visible_ === '1')
                        {
                            lFormItemList_0.push(lFormItem_0);
                        }
                    }
                }
            }

            if(lFormItemList_0.length > lMinSectionItems)
            {
                var lSectionCount =  Math.ceil(lFormItemList_0.length/lMinSectionItems);
                if((lSectionCount + lNumSections) >= lMaxSections)
                {
                    lNumSections = lMaxSections;
                }
                else
                {
                    lNumSections = lSectionCount + lNumSections;
                }
            }
            else if(lFormItemList.length <= lMinSectionItems)
            {
                lNumSections = 1;
            }

            var lSectionNumControlMap = {};
            var lMaxSectionItems = 0;
            CSHelperUtil.cfSort(lFormItemList_0, 'sequence_')
            for(var ind=0; ind<lFormItemList_0.length; ind++)
            {
                var lFormItem = lFormItemList_0[ind];
                var lSequence = parseInt(lFormItem.sequence_, 10);
                var lIndex = Math.ceil(lSequence/lMinSectionItems) - 1;
                var lSectionControlList = null;
                lSectionControlList = lSectionNumControlMap[lIndex + lNumCollectionSections];
                if(!lSectionControlList)
                {
                    lSectionControlList = [];
                    lSectionNumControlMap[lIndex + lNumCollectionSections] = lSectionControlList;
                }
                
                lSectionControlList.push(lFormUtil.cfGetControl(lFormItem, fOnChange));
                lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList.length);
            }

            var lInd = 0;
            for(var lId in lFormItemMap)
            {
                var lParentObj = lParentFormItemObjectMap[lId];
                if(lParentObj)
                {
                    var lSectionControlList_0 = null;
                    lSectionControlList_0 = lSectionNumControlMap[lInd];
                    if(!lSectionControlList_0)
                    {
                        lSectionControlList_0 = [];
                        lSectionNumControlMap[lInd] = lSectionControlList_0;
                    }

                    if(lParentObj.control_type_ !== 'cs_grid')
                    {
                        lSectionControlList_0.push(lFormUtil.cfGetControl(lParentObj, fOnChange));
                    }
                    else
                    {
                        var lpcfObj = lFormUtil.cfGetControl(lParentObj, fOnChange, lFormItemMap);
                        if(lpcfObj)
                        {
                            lSectionControlList_0.push(lpcfObj);
                        }
                    }

                    var lCollectionList = lFormItemMap[lId];
                    if(lCollectionList)
                    {
                        var lNumRows = 0;
                        for(var lclInd=0; lclInd<lCollectionList.length; lclInd++)
                        {
                            var lChildObj = lCollectionList[lclInd];
                            if((lParentObj.control_type_ !== 'cs_grid') && (lParentObj.control_type_ !== 'accordion'))
                            {
                                var lcfObj = lFormUtil.cfGetControl(lChildObj, fOnChange, lFormItemMap);
                                if(lcfObj)
                                {
                                    lSectionControlList_0.push(lcfObj);
                                }
                            }
                            else if((lParentObj.control_type_ === 'accordion') && (lChildObj.control_type_ !== 'summary'))
                            {
                                var lcfObj_0 = lFormUtil.cfGetControl(lChildObj, fOnChange, lFormItemMap);
                                if(lcfObj_0)
                                {
                                    lSectionControlList_0.push(lcfObj_0);
                                }
                            }
                        }

                        lMaxSectionItems = Math.max(lMaxSectionItems, lSectionControlList_0.length + lNumRows * 0.85);
                    }

                    lInd++;
                }
            }

            for(var lSectionNum=0; lSectionNum<lNumSections; lSectionNum++)
            {
                var lSection = null;
                var lBkgColor = "";
                if((lSectionNum % 2) === 0)
                {
                    lBkgColor = "#FFFFFF";
                }
                else
                {
                    lBkgColor = "#FFFFFF";
                }
/*
                lSection = <CSCOVerticalSection 
                    pBackgroundColor    = {lBkgColor} 
                    pId                 = {lSectionNum} 
                    key                 = {lSectionNum}
                    pNumSections        = {lNumSections}
                    pMaxSectionItems    = {lMaxSectionItems + 1}
                    pIsCriteriaSection  = {false}
                    pWidth              = {lSectionWidth}
                    pPadding            = {lPadding}
                    pChildren           = {lSectionNumControlMap[lSectionNum]}/>
*/
                lSections.push(lSection);
            }
        }

        return lSections;
    }

    cfGetDataCollection(lFormData)
    {
        var lRetCol = null;
        if(Array.isArray(lFormData))
        {
            for(var lInd=0; lInd<lFormData.length; lInd++)
            {
                var lFormItemData = lFormData[lInd];
                var lCol = this.cfGetDataFieldCollection(lFormItemData);
                if(!lRetCol)
                {
                    lRetCol = lCol;
                }
                else
                {
                    const lKeys = Object.keys(lCol);
                    if(lKeys && (lKeys.length > 0))
                    {
                        for(var lKeyInd=0; lKeyInd<lKeys.length; lKeyInd++)
                        {
                            var lKey = lKeys[lKeyInd];
                            if(lRetCol[lKey] && (lRetCol[lKey] instanceof Array) && lCol[lKey])
                            {
                                Array.prototype.push.apply(lRetCol[lKey], lCol[lKey]);
                            }
                        }
                    }
                }
            }
        }
        else
        {
            lRetCol = this.cfGetDataFieldCollection(lFormData);
        }

        return lRetCol;
    }

    cfGetDataFieldCollection(lFormData)
    {
        var lRetCol = null;
        const lKeys = Object.keys(lFormData);
        if(lKeys && (lKeys.length > 0))
        {
            lRetCol = {};

            for(var lInd=0; lInd<lKeys.length; lInd++)
            {
                var lKey = lKeys[lInd];
                if(!(lFormData[lKey] instanceof Array))
                {
                    var lVal = [];
                    lVal.push(lFormData[lKey]);                            
                    lRetCol[ lKey ] = lVal;
                }
                else
                {
                    var lColl = lFormData[lKey];
                    if(lColl.length > 0)
                    {
                        var lColObj = {}
                        var lColKeys = Object.keys(lColl[0]);
                        for(var lcInd=0; lcInd<lColKeys.length; lcInd++)
                        {
                            var lCKey = lColKeys[lcInd];
                            var lCVal = [];
                            for(var lvInd=0; lvInd<lColl.length; lvInd++)
                            {
                                var lColVal = lColl[lvInd];
                                lCVal.push(lColVal[lCKey]);
                            }

                            lColObj[lCKey] = lCVal;
                        }
                        
                        lRetCol[ lKey ] = lColObj;
                    }
                }
            }
        }

        return lRetCol;
    }

    cfPromptSave(lActionItem, lResponseFn)
    {
        if(lActionItem.hasOwnProperty('cs_form_validation_'))
        {
            var lValObj = null;
            var lValidationColl = lActionItem.cs_form_validation_;
            for(var lFv_0=0; lFv_0<lValidationColl.length; lFv_0++)
            {
                lValObj = lValidationColl[lFv_0];
                if(lValObj.trigger_point_ === "BEFORE_SAVE")
                {
                    break;
                }
            }

            if(lValObj)
            {
                var lTitle = lValObj.title_;
                if(!lTitle)
                {
                    lTitle = lActionItem.title_;
                }

                Swal.fire(
                    {
                        title               : lTitle,
                        text                : lValObj.message_,
                        type                : 'warning',
                        showCancelButton    : true,
                        cancelButtonText    : 'No',
                        confirmButtonText   : 'Yes',
                        allowOutsideClick   : false,
                        showLoaderOnConfirm : true,
                        onClose : (lResponse) => {}
                    }
                ).then((lResult) => 
                    {
                        if(lResponseFn)
                        {
                            lResponseFn(lResult); 
                        }
                    }
                );
            }
        }
    }

    cfProcessSave(lActionItem, lFormData, fCallBack)
    {
        var lFormUtil = CSFormUtil.cfGetInstance();
        var lServiceUtil = CSServiceUtil.cfGetInstance();
        if(lActionItem && lFormData && lFormUtil && lServiceUtil)
        {
            var lServiceCode = lActionItem.on_click_service_code_;
            var lRequestId = lActionItem.request_id_;
            if(lServiceCode || lRequestId)
            {
                var lServiceObject = lServiceUtil.cfGetServiceObject(lServiceCode);
                if(lServiceObject)
                {
                    var lRequestUtil = new CSRequestUtil('SAVE', lServiceCode, lServiceObject.type_, lRequestId);
                    if(lRequestUtil)
                    {
                        var lFormSaveData = this.cfGetDataFieldCollection(lFormData);
                        if(lFormSaveData)
                        {
                            lRequestUtil.cfSetRequestData(lFormSaveData);            
                            lRequestUtil.cfProcessRequest(fCallBack);
                        }
                    }
                }
            }
        }
    }

    cfProcessSaveResponse(lCode, lMsg, lActionItem, lResponse)
    {
        if(lActionItem)
        {
            var lRetValueMsgMap = {}
            var lValidationColl = lActionItem.cs_form_validation_;
            for(var lFv_0=0; lFv_0<lValidationColl.length; lFv_0++)
            {
                var lValObj = lValidationColl[lFv_0];
                if((lValObj.trigger_point_ === "AFTER_SAVE") && lValObj.ret_val_)
                {
                    lRetValueMsgMap[lValObj.ret_val_] = lValObj;
                }
            }

            var lObj = lRetValueMsgMap[lCode];
            if(lObj)
            {
                var lTitle = lObj.title_;
                if(!lTitle)
                {
                    lTitle = lActionItem.title_;
                }

                if(((lCode === '1') || (lCode === 1)) && (lObj.ret_val_ === '1'))
                {
                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lObj.message_,
                            type                : 'success',
                            allowOutsideClick   : false
                        }
                    );
                }
                else
                {
                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lObj.message_,
                            type                : 'error',
                            allowOutsideClick   : false
                        }
                    );
                }
            }
            else
            {
                if(lCode === 1)
                {
                    if(!lMsg)
                    {
                        lMsg = 'Save Successful !';
                    }

                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lMsg,
                            type                : 'success',
                            allowOutsideClick   : false
                        }
                    );
                }
                else
                {
                    if(!lMsg)
                    {
                        lMsg = 'Internal Server Error !';
                    }

                    Swal.fire(
                        {
                            title               : lTitle,
                            text                : lMsg,
                            type                : 'error',
                            allowOutsideClick   : false
                        }
                    );
                }
            }
        }
    }
  
    cfGetGroupCollection(lCollection, lGroupFields, lKeyField) 
    {
        var lDataFieldObjectMap = {};
        var lRetValue = {};

        if(!lKeyField)
        {
            lKeyField = 'id_';
        }

        if(lCollection && lGroupFields && (lGroupFields.length > 0))
        {
            for(var lInd_1=0; lInd_1<lGroupFields.length; lInd_1++)
            {   
                var lGroupingName = lGroupFields[lInd_1];
                for(var lInd_0=0; lInd_0<lCollection.length; lInd_0++)
                {
                    var lDataItem = lCollection[lInd_0];

                    var lTreeItem = {};
                    lTreeItem.key = lDataItem[lKeyField];
                    lTreeItem.data = lDataItem;
                    if(!lRetValue.keyObjectMap)
                    {
                        lRetValue.keyObjectMap = {};
                    }

                    lRetValue.keyObjectMap[lTreeItem.key] = lTreeItem;

                    var lObj = lDataFieldObjectMap[lDataItem[lGroupingName]];
                    if(!lObj)
                    {
                        lDataFieldObjectMap[lDataItem[lGroupingName]] = lTreeItem;
                        if(!lRetValue.collection)
                        {
                            lRetValue.collection = [];
                        }

                        lRetValue.collection.push(lTreeItem);
                    }
                    else
                    {
                        if(!lObj.children)
                        {
                            lObj.children = [];
                        }

                        lObj.children.push(lTreeItem);
                    }
                }
            }
        }

        return lRetValue;
    }

    cfGetGroupedCollection(lCollection, lGroupFields, lGroupFieldGridItemObjectMap)
    {
        var lRetValue = {};

        var lL1KeyObjectMap = {}
        var lL1KeyCount = 0;
        
        if(lCollection && lGroupFields && (lGroupFields.length > 0))
        {
            var lGroupFieldLength = lGroupFields.length;
            var lDataFieldObjectMap = {};
            var lIndex = 0;
            var lGroupField = lGroupFields[0];
            var lDataField = null;
            for(var lInd_0=0; lInd_0<lCollection.length; lInd_0++)
            {
                var lDataObj = lCollection[lInd_0];
                lDataField = lDataObj[lGroupField];
                var lKeyObj1 = lL1KeyObjectMap[lDataField];
                if(!lKeyObj1)
                {
                    var lkey1 = 'L1' + (++lL1KeyCount).toString();
                    lKeyObj1 = {};
                    lKeyObj1.key = lkey1;
                    lKeyObj1.data = {};
                    lKeyObj1.data[lGroupField] = lDataField;
                    lKeyObj1.data['__NAME__'] = lDataField;
                    lKeyObj1.children = [];

                    lL1KeyObjectMap[lDataField] = lKeyObj1;
                    if(!lRetValue.collection)
                    {
                        lRetValue.collection = [];
                    }
            
                    lRetValue.collection.push(lKeyObj1);
                }

                if(lGroupFieldLength === 1)
                {
                    var lEntityObject = {}
                    lEntityObject.key = 'L2' + (lIndex + 1).toString();
                    lEntityObject.data = lDataObj;
                    lDataFieldObjectMap[lEntityObject.key] = lEntityObject;

                    lKeyObj1.children.push(lEntityObject);
                }
                else
                {
                    if(!lKeyObj1.collection)
                    {
                        lKeyObj1.collection = [];
                    }

                    lKeyObj1.collection.push(lDataObj);
                }
            }

            if(lGroupFieldLength > 1)
            {
                lGroupField = lGroupFields[1];
                var lKeys = Object.keys(lL1KeyObjectMap);
                if(lKeys)
                {
                    for(var lkInd=0; lkInd<lKeys.length; lkInd++)
                    {
                        var lKey = lKeys[lkInd];
                        var lL1Object = lL1KeyObjectMap[lKey];
                        if(lL1Object.hasOwnProperty('collection'))
                        {
                            var lL1Children = lL1Object.collection;
                            if(lL1Children)
                            {
                                var lL2KeyObjectMap = {}
                                for(var lc1Ind=0; lc1Ind<lL1Children.length; lc1Ind++)
                                {
                                    var lL2Object = lL1Children[lc1Ind];
                                    lDataField = lL2Object[lGroupField];
                                    var lKeyObj2 = lL2KeyObjectMap[lDataField];
                                    if(!lKeyObj2)
                                    {
                                        var lkey2 = lkey1 + (lkInd + 1).toString();
                                        lKeyObj2 = {};
                                        lKeyObj2.key = lkey2;
                                        lKeyObj2.data = {};
                                        lKeyObj2.data[lGroupFields[0]] = lL2Object[lGroupFields[0]];
                                        lKeyObj2.data[lGroupField] = lDataField;
                                        lKeyObj2.data['__NAME__'] = lDataField;
                                        lKeyObj2.children = [];
                                        if(lGroupFieldLength === 2)
                                        {
                                            lKeyObj2.data = CSHelperUtil.cfGetObjectCopy(lL2Object);
                                            lKeyObj2.data[lGroupFields[0]] = lL2Object[lGroupFields[0]];
                                            lKeyObj2.data[lGroupField] = lDataField;
                                            lKeyObj2.data['__NAME__'] = lDataField;
                                        }
                    
                                        lL2KeyObjectMap[lDataField] = lKeyObj2;

                                        if(!lL1Object.children)
                                        {
                                            lL1Object.children = [];
                                        }

                                        lL1Object.children.push(lKeyObj2);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return lRetValue;
    }


    static cfEnableAction(lActionItem, lSelectedData, lFormItems, lFormDataList)
    {
        var lEnabled = false;
        if(lActionItem)
        {
            var lActionType = lActionItem.component_type_;
            lEnabled = (lActionItem.enabled_ === true) || (lActionItem.enabled_ === '1');
            if(lActionType === 'CRITERIA_ACTION')
            {
                if(lFormItems && lSelectedData)
                {
                    var lIsEnabled0 = true;
                    for(var lFiInd=0; lFiInd< lFormItems.length; lFiInd++)
                    {
                        var lFormItem = lFormItems[lFiInd];
                        if(lFormItem.required_ === '1')
                        {
                            if(lSelectedData.hasOwnProperty(lFormItem.criteria_field_))
                            {
                                var lSelectedFieldValue = lSelectedData[lFormItem.criteria_field_];
                                if(!lSelectedFieldValue)
                                {
                                    lIsEnabled0 = false;
                                    break;
                                }
                            }
                            else
                            {
                                lIsEnabled0 = false;
                                break;
                            }
                        }    
                    }
                
                    lEnabled = lIsEnabled0;
                }
            }
            else if(lActionType === 'SEARCH_ACTION')
            {
                if(lSelectedData)
                {
                    var lSelectedProperty;
                    if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
                    {
                        for(var lFapInd=0; lFapInd<lActionItem.cs_form_action_properties_.length; lFapInd++)
                        {
                            var lFapObj = lActionItem.cs_form_action_properties_[lFapInd];
                            if((lFapObj.type_ === 'item_selection') && (lFapObj.selection_property_) && (lFapObj.selection_value_))
                            {
                                if(lSelectedData.hasOwnProperty(lFapObj.selection_property_))
                                {
                                    lSelectedProperty = lSelectedData[lFapObj.selection_property_];
                                    if(lFapObj.selection_value_ === '#NOT_NULL#')
                                    {
                                        if(lSelectedProperty)
                                        {
                                            lEnabled = (lFapObj.value_ === 'true');
                                        }
                                        else
                                        {
                                            lEnabled = !(lFapObj.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj.selection_value_ === '#NULL#')
                                    {
                                        if(!lSelectedProperty)
                                        {
                                            lEnabled = (lFapObj.value_ === 'true');
                                        }
                                        else
                                        {
                                            lEnabled = !(lFapObj.value_ === 'true');
                                        }
                                    }
                                    else
                                    {
                                        if(lSelectedProperty && lFapObj.selection_value_)
                                        {
                                            var lEqual = (lSelectedProperty === lFapObj.selection_value_);
                                            if(lEqual)
                                            {
                                                if(lFapObj.value_ === 'true')
                                                {
                                                    lEnabled = true;
                                                }
                                                else
                                                {
                                                    lEnabled = false;
                                                }
                                            }
                                            else
                                            {
                                                if(lFapObj.value_ === 'true')
                                                {
                                                    lEnabled = false;
                                                }
                                                else
                                                {
                                                    lEnabled = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            else if((lActionType === 'DETAILS_ACTION') || (lActionType === 'DETAILS_ITEM_DETAILS_ACTION'))
            {
                if(lFormItems && (lSelectedData || lFormDataList))
                {
                    var lIsEnabled = true;
                    for(var lFiInd0=0; lFiInd0< lFormItems.length; lFiInd0++)
                    {
                        var lFormItem0 = lFormItems[lFiInd0];
                        if(lFormItem0.required_ === '1')
                        {
                            if(lFormDataList && (lFormDataList instanceof Array))
                            {
                                var lEnableFlag0 = false;
                                for(var lFdInd=0; lFdInd<lFormDataList.length; lFdInd++)
                                {
                                    var lFormDataObj = lFormDataList[lFdInd];
                                    if(lFormDataObj.hasOwnProperty(lFormItem0.data_field_))
                                    {
                                        var lSelectedValue = lFormDataObj[lFormItem0.data_field_];
                                        if(lSelectedValue)
                                        {
                                            lEnableFlag0 = true;
                                            break;
                                        }
                                    }
                                }

                                if(!lEnableFlag0)
                                {
                                    lIsEnabled = false;
                                    break;
                                }
                            }
                            else if(lSelectedData)
                            {
                                if(lSelectedData.hasOwnProperty(lFormItem0.data_field_))
                                {
                                    var lSelectedFieldValue0 = lSelectedData[lFormItem0.data_field_];
                                    if(!lSelectedFieldValue0)
                                    {
                                        lIsEnabled = false;
                                        break;
                                    }
                                }
                                else
                                {
                                    lIsEnabled = false;
                                    break;
                                }
                            }
                        }    
                    }

                    if(lActionItem.hasOwnProperty('cs_form_action_properties_'))
                    {
                        for(var lFapInd0 = 0; lFapInd0<lActionItem.cs_form_action_properties_.length; lFapInd0++)
                        {
                            var lFapObj0 = lActionItem.cs_form_action_properties_[lFapInd0];
                            if((lFapObj0.type_ === 'item_selection') && (lFapObj0.selection_property_) && (lFapObj0.selection_value_))
                            {
                                if(lSelectedData.hasOwnProperty(lFapObj0.selection_property_))
                                {
                                    lSelectedProperty = lSelectedData[lFapObj0.selection_property_];
                                    if(lFapObj0.selection_value_ === '#NOT_NULL#')
                                    {
                                        if(lSelectedProperty)
                                        {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else
                                        {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else if(lFapObj0.selection_value_ === '#NULL#')
                                    {
                                        if(!lSelectedProperty)
                                        {
                                            lIsEnabled = (lFapObj0.value_ === 'true');
                                        }
                                        else
                                        {
                                            lIsEnabled = !(lFapObj0.value_ === 'true');
                                        }
                                    }
                                    else
                                    {
                                        if(lSelectedProperty && lFapObj0.selection_value_)
                                        {
                                            var lEqual0 = (lSelectedProperty === lFapObj0.selection_value_);
                                            if(lEqual0)
                                            {
                                                if(lFapObj0.value_ === 'true')
                                                {
                                                    lIsEnabled = true;
                                                }
                                                else
                                                {
                                                    lIsEnabled = false;
                                                }
                                            }
                                            else
                                            {
                                                if(lFapObj0.value_ === 'true')
                                                {
                                                    lIsEnabled = false;
                                                }
                                                else
                                                {
                                                    lIsEnabled = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    lEnabled = lIsEnabled;
                }
            }
            else if(lActionType === 'DETAILS_ITEM_SEARCH_ACTION')
            {
                if(lSelectedData)
                {
                    lEnabled = true;
                }
            }
        }

        return lEnabled;
    }

    static cfSetDependentFieldsData(lFormItems, lFormData, lChangedFormItem, lSelectedData, lIdFormItemMap)
    {
        var lRetData = lFormData;
        if(lRetData && lFormItems && lChangedFormItem)
        {
            for(var lInd=0; lInd<lFormItems.length; lInd++)
            {
                var lFormItem = lFormItems[lInd];
                if(lFormItem.dependent_id_ && (lFormItem.dependent_id_ === lChangedFormItem.id_))
                {
                    if(lSelectedData)
                    {
/* 
                        var lDependentObject;
                        if(lIdFormItemMap)
                        {
                            lDependentObject = lIdFormItemMap[lFormItem.dependent_id_];
                        }
*/
                        if(lChangedFormItem.control_type_ === 'combo_box')
                        {
                            if(!lFormItem.reference_field_)
                            {
                                lRetData.dependent_value_ = lSelectedData[lFormItem.dependent_field_];
                                lFormItem.dependent_value_ = lSelectedData[lFormItem.dependent_field_];
                                CSFormUtil.cfSetDependentFieldsData(lFormItems, lRetData, lFormItem, lSelectedData[lFormItem.dependent_field_], lIdFormItemMap)
                            }
                            else
                            {
                                lRetData.dependent_value_ = lSelectedData[lFormItem.reference_field_];
                                lFormItem.dependent_value_ = lSelectedData[lFormItem.reference_field_];
                                CSFormUtil.cfSetDependentFieldsData(lFormItems, lRetData, lFormItem, lSelectedData[lFormItem.reference_field_], lIdFormItemMap)
                            }
                        }
                        else
                        {
                            lRetData[lFormItem.data_field_] = lSelectedData;
                        }
                    }
                }
            }
        }

        return lRetData;
    }

    static cfGetComputedFieldsData(lRowData, lFormItems, lIdFormItemMap)
    {
        var lVariableValueMap = {};
        if(lRowData && lFormItems && lIdFormItemMap)
        {
            for(var lInd=0; lInd<lFormItems.length; lInd++)
            {
                var lFormItem = lFormItems[lInd];
                if(lFormItem.hasOwnProperty('cs_form_item_computation_'))
                {
                    var lFICompList = lFormItem.cs_form_item_computation_;
                    if(lFICompList && lIdFormItemMap)
                    {
                        for(var lFicInd=0; lFicInd<lFICompList.length; lFicInd++)
                        {
                            var lFICompObj = lFICompList[lFicInd];
                            var lComputedValue = null;
                            var lField1Obj;
                            var lField2Obj;
                            var lField1Value;
                            var lField2Value;
                            var lDataVal;
                                    
                            if(lFICompObj.field_1_type_ && lFICompObj.field_1_data_field_ )
                            {
                                if(lFICompObj.field_1_type_ === 'control')
                                {
                                    lField1Obj = lIdFormItemMap[lFICompObj.field_1_ref_id_];
                                    if(lField1Obj)
                                    {
                                        lField1Value = lRowData[lFICompObj.field_1_data_field_];
                                    }
                                }
                                else if(lFICompObj.field_1_type_ === 'computed')
                                {
                                    lField1Value = lVariableValueMap[lFICompObj.field_1_data_field_];
                                }

                                if(lFICompObj.field_1_constant_)
                                {
                                    lDataVal = lFICompObj.field_1_constant_;
                                }
                            }
                                    
                            if(lFICompObj.field_2_type_)
                            {
                                if(lFICompObj.field_2_data_field_)
                                {
                                    if(lFICompObj.field_2_type_ === 'control')
                                    {
                                        lField2Obj = lIdFormItemMap[lFICompObj.field_2_ref_id_];
                                        if(lField2Obj)
                                        {
                                            lField2Value = lRowData[lFICompObj.field_2_data_field_];
                                        }
                                    }
                                    else if(lFICompObj.field_2_type_ === 'computed')
                                    {
                                        lField2Value = lVariableValueMap[lFICompObj.field_2_data_field_];
                                    }
                                }
                            }
                            else if(lFICompObj.field_2_type_ === 'constant')
                            {
                                lField2Value = lFICompObj.field_2_constant_;
                                if(lFICompObj.data_val_)
                                {
                                    lDataVal = lFICompObj.data_val_;
                                }
                            }

                            lComputedValue = CSFormUtil.cfGetComputedValue(lField1Value, lField2Value, lFICompObj.operation_, lDataVal);
                            lVariableValueMap[lFICompObj.variable_] = lComputedValue;
                        }

                        if(lVariableValueMap.hasOwnProperty[lFormItem.data_field_])
                        {
                            lFormItem.mValue = lVariableValueMap[lFormItem.data_field_];
                        }
                    }
                }
            }
        }

        return lVariableValueMap;
    }

    static cfGetFormItemInputString(lSelectedFormItem, lFormData)
    {
        var lRetVal;
        if(lSelectedFormItem && (lSelectedFormItem.data_type_ === 'input_string') && lFormData && lSelectedFormItem.hasOwnProperty('cs_form_item_properties_'))
        {
            CSHelperUtil.cfSort(lSelectedFormItem.cs_form_item_properties_, 'sequence_')
            for(var lFipInd=0; lFipInd<lSelectedFormItem.cs_form_item_properties_.length; lFipInd++)
            {
                var lFipObj = lSelectedFormItem.cs_form_item_properties_[lFipInd];
                if((lFipObj.type_ === 'data_selection') && lFipObj.value_)
                {
                    if(!lRetVal)
                    {
                        lRetVal = '';
                    }

                    var lStr = ""
                    if(lFipObj.property_ === 'data_field')
                    {
                        lStr = lFipObj.value_ + lFormData[lFipObj.selection_property_];
                    }
                    else if(lFipObj.property_ === 'constant')
                    {
                        lStr = lFipObj.value_;
                    }

                    if(lStr)
                    {
                        if(lRetVal)
                        {
                            lRetVal = lRetVal + lStr;
                        }
                        else
                        {
                            lRetVal = lStr;
                        }
                    }
                }
            }
        }

        return lRetVal;
    }

    static cfGetGridItemInputString(lSelectedGridItem, lFormData)
    {
        var lRetVal;
        if(lSelectedGridItem && lFormData && lSelectedGridItem.hasOwnProperty('cs_form_grid_item_properties_'))
        {
            CSHelperUtil.cfSort(lSelectedGridItem.cs_form_grid_item_properties_, 'sequence_')
            for(var lFipInd=0; lFipInd<lSelectedGridItem.cs_form_grid_item_properties_.length; lFipInd++)
            {
                var lFipObj = lSelectedGridItem.cs_form_grid_item_properties_[lFipInd];
                if((lFipObj.type_ === 'data_selection') && lFipObj.value_)
                {
                    if(!lRetVal)
                    {
                        lRetVal = '';
                    }

                    var lStr = ""
                    if(lFipObj.property_ === 'data_field')
                    {
                        lStr = lFipObj.value_ + lFormData[lFipObj.selection_property_];
                    }
                    else if(lFipObj.property_ === 'constant')
                    {
                        lStr = lFipObj.value_;
                    }

                    if(lStr)
                    {
                        if(lRetVal)
                        {
                            lRetVal = lRetVal + lStr;
                        }
                        else
                        {
                            lRetVal = lStr;
                        }
                    }
                }
            }
        }

        return lRetVal;
    }

    static cfGetComputedValue(lField1Value, lField2Value, lOperation, lDataVal)
    {
        var lComputedVal = null;
        switch(lOperation)
        {
            case 'date_add':
            break;
            
            case 'date_diff':
            break;
            
            case 'difference':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = Number(lField1Value) - Number(lField2Value);
            break;
            
            case 'divide':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                if(Number(lField2Value) > 0)
                {
                    lComputedVal = Number(lField1Value) / Number(lField2Value);
                }
            break;
            
            case 'equal':
                lComputedVal = (lField1Value === lField2Value) ? lDataVal : lComputedVal;
            break;
            
            case 'greater':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) > Number(lField2Value)) ? lDataVal : lComputedVal;
            break;
            
            case 'lesser':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) < Number(lField2Value)) ? lDataVal : lComputedVal;
            break;
            
            case 'minimum':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = (Number(lField1Value) <= Number(lField2Value)) ? lField1Value : lField2Value;
            break;
            
            case 'multiply':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = Number(lField1Value) * Number(lField2Value);
            break;
            
            case 'neg_val':
            break;
            
            case 'not_equal':
            break;
            
            case 'num_days':
            break;
            
            case 'pos_val':
            break;
            
            case 'sum':
                if(!lField1Value)
                {
                    lField1Value = 0;
                }

                if(!lField2Value)
                {
                    lField2Value = 0;
                }

                lComputedVal = Number(lField1Value) + Number(lField2Value);
            break;
            
            default:
            break;
        }

        return lComputedVal;
    }
}

export default CSFormUtil;