import React, { useContext, useEffect, useState } from 'react'
import { Carousel } from 'primereact/carousel';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';


import Slider from './Slider';
import S_Carousel from './S_Carousel';
import S_ImageSlider from './S_ImageSlider';
import { DataContext } from '../context/DataContext';
import { PhotoService } from '../services/PhotoService';
import CS_About from './CS_About';
import Ch_Classes from './Ch_Classes';
import CS_Contact from './CS_Contact';
import Ch_MobileApp from './Ch_MobileApp';

export default function Chess_HomePage() {
   const [images, setImages] = useState(null);
   const ProductData = useContext(DataContext);
   const { ProductName, HeaderTitle, SubTitle1, SubTitle2, Image, BannerImage, Menu1, Menu2, Menu3, Menu4, Menu5, Menu6, Menu7, Menu8, Menu9, Color } = ProductData
   useEffect(() => {
      PhotoService.getPhotos().then((data) => setImages(data.slice(0, 1)));
   }, []);
   const responsiveOptions = [
      {
         breakpoint: '1199px',
         numVisible: 1,
         numScroll: 1
      },
      {
         breakpoint: '991px',
         numVisible: 2,
         numScroll: 1
      },
      {
         breakpoint: '767px',
         numVisible: 1,
         numScroll: 1
      }
   ];
   const itemTemplate = (item) => {
      return <img src={item.image} alt={item.description} style={{ width: '100%', display: 'block' }} />;
   };

   return (

      <div>
         <div className="">
            <Carousel value={images} numVisible={1} numScroll={1} responsiveOptions={responsiveOptions} className="custom-carousel w-full " circular
                itemTemplate={itemTemplate} showNavigators={false} showIndicators={true} />
         </div>
         <CS_About />
         <Ch_Classes />
         <CS_Contact />
      </div>
   )
}
