
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import CS_Topbar from './Screens/CS_Topbar';
import Chess_HomePage from './Screens/Chess_HomePage';
import CS_About from './Screens/CS_About.js';
import CS_Contact from './Screens/CS_Contact.js';
import Ch_Classes from './Screens/Ch_Classes.js';
import { DataContextProvider } from './context/DataContext';
import './assets/website.css'
import 'primeicons/primeicons.css';
import Ch_MobileApp from './Screens/Ch_MobileApp.js';

function App() {
  return (
    <DataContextProvider>
       <Routes>
        <Route path="/" element={<CS_Topbar/>}>
          <Route index element={<Chess_HomePage />} />
          <Route path="home" element={<Chess_HomePage />} />
          <Route path="about" element={<CS_About/>} />
          <Route path="classes" element={<Ch_Classes/>} />
          {/* <Route path="mobileApp" element={<Ch_MobileApp/>} /> */}
          <Route path="contact" element={<CS_Contact/>} />
        </Route>
      </Routes>
    </DataContextProvider>
  );
}

export default App;